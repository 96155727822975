import axios from "axios";
import bcrypt from "bcryptjs";
import { generatePassword } from "../utils/Password";

export async function validateAuth(code, email) {
  try {
    const data = {
      code,
      email,
    };
    const authenticationUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/authenticate`;
    const response = await axios.post(authenticationUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true, // This endpoint sets the httpOnly cookie
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function loginUser(data) {
  const loginUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/login`;
  try {
    const response = await axios.post(loginUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data.message;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function registerUser(data) {
  try {
    const salt = bcrypt.genSaltSync(10);
    data.password = bcrypt.hashSync(data.password, salt);
    delete data.passwordRepeat;

    const registerUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/register`;
    const response = await axios.post(registerUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data.message;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function logoutUser() {
  try {
    const logoutUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/logout`;
    await axios.post(
      logoutUrl,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function temporaryPassword(uuid) {
  try {
    const password = generatePassword(12);
    const salt = bcrypt.genSaltSync(10);
    const data = {
      uuid: uuid,
      password: bcrypt.hashSync(password, salt),
    };

    const temporaryPasswordUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/password/temporary`;
    await axios.post(temporaryPasswordUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return password;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function resetPassword(data) {
  try {
    const salt = bcrypt.genSaltSync(10);
    data.password = bcrypt.hashSync(data.password, salt);

    const resetPasswordUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/password/reset`;
    const result = await axios.post(resetPasswordUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return result.data.message;
  } catch (e) {
    throw {
      status: e.response?.status || 500,
      message: e.response?.data.message || "Internal server error",
    };
  }
}
