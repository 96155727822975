import axios from "axios";

export async function getUser(uuid) {
  const data = {
    uuid: uuid,
  };
  try {
    const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users/get`;
    const response = await axios.post(usersUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function getUsers() {
  try {
    const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users`;
    const response = await axios.post(
      usersUrl,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function deleteUser(uuid) {
  const data = {
    uuid: uuid,
  };
  try {
    const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users/delete`;
    const response = await axios.post(usersUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data.message;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function updateUser(data) {
  try {
    const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users/update`;
    const response = await axios.post(usersUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data.message;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function addUserInvite(data) {
  try {
    const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users/invite/add`;
    const response = await axios.post(usersUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data.message;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function deleteUserInvite(data) {
  try {
    const usersUrl = `${process.env.REACT_APP_API_BASE_URL}/users/invite/delete`;
    const response = await axios.post(usersUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data.message;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}
