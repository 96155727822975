import { React, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { loginUser } from "../services/AuthService";
import Authenticator from "../components/Authenticator";
import Input from "../components/Input";
import { useNavigate } from "react-router-dom";
import { verifyToken } from "../services/TokenService";
import { getCSRFToken } from "../services/TokenService";
import NavbarFrontPage from "../components/NavbarFrontPage";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const initialLogin = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const ADMIN = 1;
  const RESET_PASSWORD = -1;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [secretUrl, setSecretUrl] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Check if user is already logged in
  useEffect(() => {
    async function verify() {
      try {
        await getCSRFToken();
        const payload = await verifyToken();
        if (payload.role === RESET_PASSWORD) {
          navigate(`/user/resetPassword`);
          return;
        } else if (payload.role === ADMIN) {
          navigate("/users");
          return;
        } else {
          navigate("/dashboard");
          return;
        }
      } catch (e) {
        // User is not logged in or server error
        return;
      }
    }

    verify();
  }, []);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Verplicht")
      .email("Vul een geldig email adres in"),
    password: Yup.string().required("Verplicht"),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    try {
      const result = await loginUser(data);
      if (result !== true) {
        setSecretUrl(result);
        setEmail(data.email);
      } else if (result === true) {
        setEmail(data.email);
      } else {
        setError("Er ging iets mis, probeer het opnieuw.");
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavbarFrontPage />
      <div className="flex justify-center w-full box-border pt-20 px-2 sm:px-4">
        {!email ? (
          // Login screen
          <Formik
            initialValues={initialLogin}
            validationSchema={loginSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ errors, touched }) => (
              <Form className="bg-white inline-flex flex-col gap-y-4 py-4 px-6 rounded-lg w-full max-w-lg">
                <h1 className="text-2xl text-black">Login</h1>
                {error && <span className="text-red-500">{error}</span>}
                <Input
                  label="Emailadres"
                  error={errors.email}
                  touched={touched.email}
                  type="email"
                  name="email"
                />
                <Input
                  label="Wachtwoord"
                  error={errors.password}
                  touched={touched.password}
                  type="password"
                  name="password"
                />
                {loading ? (
                  <AiOutlineLoading3Quarters className="animate-spin text-3xl mt-3" />
                ) : (
                  <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-2 px-10 rounded inline-block mt-4 self-start">
                    Login
                  </button>
                )}
                <span>
                  Heb je nog geen account? <br />{" "}
                  <button
                    onClick={() => {
                      navigate("/register");
                    }}
                    className="text-indigo-700 underline"
                  >
                    Registreer
                  </button>
                </span>
              </Form>
            )}
          </Formik>
        ) : (
          // Authenticator screen after logging in
          <Authenticator secretUrl={secretUrl} email={email} />
        )}
      </div>
    </>
  );
};

export default LoginPage;
