import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatDate, getAge, getCurrentMonth } from "../utils/date";
import { timeIntervals } from "../assets/data";
import { getUserGraphs } from "../services/DataService";
import { getUser } from "../services/UsersService";
import { formatUser } from "../utils/users";
import { formatGraph } from "../utils/graphs";
import { useNavigate } from "react-router-dom";
import Graph from "../components/Graph";
import { FaPlus, FaMinus } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Input from "../components/Input";
import Model from "../components/Model";
import {
  getNotes,
  saveNote,
  deleteNote,
  getBodyParts,
} from "../services/NotesService";
import { useAuth } from "../components/ProtectedRoute";

const addNoteInitialValues = {
  date: "",
  note: "",
};

const initialPopup = {
  visible: false,
  title: "",
  text: "",
  onConfirm: null,
  onCancel: null,
};

const DashboardPage = () => {
  const ADMIN = 1;
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const [user, setUser] = useState(null);
  const [graphs, setGraphs] = useState([]);
  const [fullscreenGraph, setFullscreenGraph] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [graphFilters, setGraphFilters] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [activeBodyPart, setActiveBodyPart] = useState(null);
  const [notes, setNotes] = useState([]);
  const [active, setActive] = useState("Dashboard");
  const [openAddNote, setOpenAddNote] = useState(false);
  const [popup, setPopup] = useState(initialPopup);
  const [formTouched, setFormTouched] = useState(false);
  const [loadingGraphs, setLoadingGraphs] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingAddNote, setLoadingAddNote] = useState(false);

  const addNoteSchema = Yup.object().shape({
    date: Yup.date().required("Verplicht"),
    note: Yup.string().required("Verplicht"),
  });

  function toggleActive(newActive) {
    function handleConfirm() {
      setActive(newActive);
      sessionStorage.setItem("active", newActive);
      setFormTouched(false);
      setOpenAddNote(false);
      setError(null);
      setSuccess(null);
      setPopup(initialPopup);
    }
    if (active === "Notities" && formTouched) {
      setPopup({
        visible: true,
        title: "Naar dashboard",
        text: "Weet je zeker dat je naar het dashboard wilt gaan? Alle niet opgeslagen notities gaan verloren.",
        onConfirm: () => {
          handleConfirm();
        },
        onCancel: () => {
          setPopup(initialPopup);
        },
      });
      return;
    }
    handleConfirm();
  }

  async function handleSubmitNote(data, resetForm) {
    setLoadingAddNote(true);
    try {
      data = {
        ...data,
        uuid: user.uuid,
        bodyPartId: activeBodyPart,
      };
      const result = await saveNote(data);
      data.id = result;
      const newNotes = [...notes, data];
      newNotes.sort((a, b) => new Date(b.date) - new Date(a.date));
      setNotes(newNotes);
      setSuccess("Notitie is succesvol opgeslagen");
      setFormTouched(false);
    } catch (error) {
      setError(error.message);
    } finally {
      resetForm({
        values: addNoteInitialValues,
      });
      setOpenAddNote(false);
      setLoadingAddNote(false);
    }
  }

  async function handleDeleteNote(id) {
    try {
      const data = {
        id,
      };
      const result = await deleteNote(data);
      setNotes(notes.filter((note) => note.id !== id));
      setPopup(initialPopup);
      setSuccess(result.message);
    } catch (error) {
      setError(error.message);
    }
  }

  async function fetchBodyParts() {
    if (user) {
      setLoadingNotes(true);
      try {
        const result = await getBodyParts();
        setBodyParts(result);
        setActiveBodyPart(result[0].id);
      } catch (error) {
        setError(error.message);
      }
    }
  }

  async function fetchNotes() {
    if (user) {
      try {
        const data = {
          uuid: user.uuid,
          bodyPartId: activeBodyPart,
        };
        const result = await getNotes(data);
        setNotes(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoadingNotes(false);
      }
    }
  }

  async function fetchData() {
    if (user) {
      setLoadingGraphs(true);
      setGraphs([]);
      // Fetch data for the current month
      const month = getCurrentMonth();
      const data = {
        uuid: user.uuid,
        birthdate: user.birthdate,
        minMonth: timeIntervals[0].intervals[month].minMonth,
        maxMonth: timeIntervals[0].intervals[month].maxMonth,
        year: new Date().getFullYear(),
      };
      try {
        const result = await getUserGraphs(data);
        const newGraphs = result.map((graph) => formatGraph(graph));
        setGraphs(newGraphs);

        // Set filters for each graph
        let filters = {};
        newGraphs.forEach((graph) => {
          filters[graph.id] = {
            "data-width": 0,
            year:
              graph.years === null || data.year in graph.years
                ? data.year
                : graph.years[0],
            monthInterval: month,
          };
        });
        setGraphFilters(filters);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoadingGraphs(false);
      }
    }
  }

  // Get user
  useEffect(() => {
    async function fetchUser() {
      try {
        const user = await getUser();
        if (user === null) {
          navigate("/dashboard");
        }
        setUser(formatUser(user));
        return user.uuid;
      } catch (e) {
        if (e.status === 401 || e.status === 403) {
          navigate("/dashboard");
        } else {
          setError(e.message);
        }
      }
    }

    const activeTab = sessionStorage.getItem("active");
    if (activeTab) {
      setActive(activeTab);
    }

    if (location.state) {
      setUser(location.state.userData);
    } else if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    } else {
      fetchUser();
    }
  }, [location.key]);

  // Get data for the graphs
  useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
    }
    fetchData();
    fetchBodyParts();
  }, [user]);

  useEffect(() => {
    if (activeBodyPart) {
      fetchNotes();
    }
  }, [activeBodyPart]);

  return (
    <>
      <div className="w-full box-border pb-32">
        <div className="flex items-center justify-between flex-wrap gap-x-4 mb-4 sm:mb-0">
          <div className="mb-4">
            {error && <span className="text-red-500">{error}</span>}
            <h1 className="text-2xl">
              {user && user.firstname + " " + user.lastname}
            </h1>
            <span className="text-m">
              Leeftijd: {user && getAge(user.birthdate)}
            </span>
          </div>

          <div className="flex items-center justify-center p-1 bg-grey-accent rounded-xl">
            <div className="relative grid grid-cols-2 text-sm">
              <button
                onClick={() => {
                  toggleActive("Dashboard");
                }}
                className={`px-4 py-2 focus:outline-none transition z-[1] ${
                  active === "Dashboard" ? "text-white" : "text-black"
                }`}
              >
                Dashboard
              </button>
              <button
                onClick={() => {
                  toggleActive("Notities");
                }}
                className={`px-4 py-2 focus:outline-none transition z-[1] ${
                  active === "Notities" ? "text-white" : "text-black"
                }`}
              >
                Notities
              </button>
              <div
                className={`absolute top-0 left-0 h-full w-1/2 bg-indigo-600 z-[0] rounded-lg transition-transform duration-300 shadow ${
                  active === "Notities" ? "translate-x-full" : ""
                }`}
              />
            </div>
          </div>
        </div>

        {active === "Dashboard" ? (
          loadingGraphs ? (
            <AiOutlineLoading3Quarters className="animate-spin text-4xl mt-3" />
          ) : (
            <div className="gap-8 grid grid-cols-1 sm:grid-cols-2">
              {graphs.map((graph, graphIndex) => (
                <Graph
                  key={graphIndex}
                  initialGraph={graph}
                  initialGraphFilter={graphFilters[graph.id]}
                  graphIndex={graphIndex}
                  fullScreen={true}
                  fullscreenGraph={fullscreenGraph}
                  setFullscreenGraph={setFullscreenGraph}
                  manageData={true}
                  user={user}
                />
              ))}
            </div>
          )
        ) : loadingNotes ? (
          <AiOutlineLoading3Quarters className="animate-spin text-4xl mt-3" />
        ) : (
          <div className="max-w-[800px]">
            <div className="w-full flex justify-between py-2">
              <select
                onChange={(e) => {
                  setActiveBodyPart(e.target.value);
                }}
                className="border border-gray-300 rounded-lg px-2 py-2"
              >
                {bodyParts.map((bodyPart, index) => (
                  <option key={index} value={bodyPart.id}>
                    {bodyPart.name}
                  </option>
                ))}
              </select>
              {auth.role === ADMIN && (
                <button
                  onClick={() => {
                    setOpenAddNote(!openAddNote);
                  }}
                >
                  {openAddNote ? (
                    <FaMinus className="text-lg" />
                  ) : (
                    <FaPlus className="text-lg" />
                  )}
                </button>
              )}
            </div>

            {success && <span className="text-green-500">{success}</span>}

            {openAddNote && (
              <Formik
                initialValues={addNoteInitialValues}
                validationSchema={addNoteSchema}
                onSubmit={(values, { resetForm }) =>
                  handleSubmitNote(values, resetForm)
                }
              >
                {({ errors, touched }) => (
                  <Form className="py-4 border-y-2">
                    <div className="flex justify-between items-center">
                      <h1 className="text-xl mb-2">Nieuwe notitie</h1>
                      {loadingAddNote ? (
                        <AiOutlineLoading3Quarters className="animate-spin text-2xl" />
                      ) : (
                        <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded inline-block">
                          Voeg toe
                        </button>
                      )}
                    </div>
                    <div className="flex flex-col gap-4">
                      <Input
                        label="Datum"
                        type="date"
                        name="date"
                        error={errors.date}
                        touched={touched.date}
                        onBlur={() => setFormTouched(true)}
                        style={"bg-grey-accent"}
                      />
                      <Input
                        label="Notitie"
                        type="text"
                        name="note"
                        element="textarea"
                        error={errors.note}
                        touched={touched.note}
                        style={"min-h-[150px] bg-grey-accent"}
                        onBlur={() => setFormTouched(true)}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {notes.length === 0 ? (
              <h1>Geen notities gevonden</h1>
            ) : (
              notes.map((note, index) => (
                <div
                  className="py-4 border-b-2 overflow-hidden w-full"
                  key={index}
                >
                  <div className="flex items-center justify-between mb-2">
                    <span>{formatDate(note.date)}</span>
                    <button
                      className="text-red-500"
                      onClick={() => {
                        setPopup({
                          visible: true,
                          title: "Notitie verwijderen",
                          text: "Weet je zeker dat je deze notitie wilt verwijderen? Deze actie wordt direct uitgevoerd en kan niet ongedaan gemaakt worden.",
                          onConfirm: () => {
                            handleDeleteNote(note.id);
                          },
                          onCancel: () => {
                            setPopup(initialPopup);
                          },
                        });
                      }}
                    >
                      Verwijder
                    </button>
                  </div>
                  <p className="break-words w-full">{note.note}</p>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <Model {...popup} />
    </>
  );
};

export default DashboardPage;
