import React from "react";
import "../styles/components/Input.css";
import { Field } from "formik";

const Input = ({
  label,
  type,
  name,
  maxValue,
  element,
  onBlur,
  error,
  touched,
  errorBorder,
  style,
}) => {
  return (
    <div className="flex flex-col gap-y-1 grow">
      <div
        className={`flex flex-wrap justify-between ${(!error || !touched || errorBorder) && !label && "hidden"}`}
      >
        {label && <label htmlFor={name}>{label}:</label>}
        {error && touched && !errorBorder && (
          <span className="text-red-500">{error}</span>
        )}
      </div>
      <Field
        id={name}
        as={element ? element : "input"}
        type={type}
        name={name}
        max={maxValue}
        className={`input bg-grey-accent rounded-md p-2 block border-red-500 ${error && touched && errorBorder && "border-2"} ${style && style}`}
        onBlur={onBlur && onBlur}
      />
    </div>
  );
};

export default Input;
