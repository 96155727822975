import axios from "axios";

export async function getUserGraphs(data) {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/data/graphs`;
  try {
    const response = await axios.post(dataUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function getUserGraph(data) {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/data/graph`;
  try {
    const response = await axios.post(dataUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function saveData(data) {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/data/save`;
  try {
    const response = await axios.post(dataUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function deleteData(data) {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/data/delete`;
  try {
    const response = await axios.post(dataUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}
