export const timeIntervals = [
  {
    value: "1m",
    label: "1M",
    intervals: [
      {
        minMonth: 1,
        maxMonth: 1,
        label: "Jan",
      },
      {
        minMonth: 2,
        maxMonth: 2,
        label: "Feb",
      },
      {
        minMonth: 3,
        maxMonth: 3,
        label: "Mrt",
      },
      {
        minMonth: 4,
        maxMonth: 4,
        label: "Apr",
      },
      {
        minMonth: 5,
        maxMonth: 5,
        label: "Mei",
      },
      {
        minMonth: 6,
        maxMonth: 6,
        label: "Jun",
      },
      {
        minMonth: 7,
        maxMonth: 7,
        label: "Jul",
      },
      {
        minMonth: 8,
        maxMonth: 8,
        label: "Aug",
      },
      {
        minMonth: 9,
        maxMonth: 9,
        label: "Sep",
      },
      {
        minMonth: 10,
        maxMonth: 10,
        label: "Okt",
      },
      {
        minMonth: 11,
        maxMonth: 11,
        label: "Nov",
      },
      {
        minMonth: 12,
        maxMonth: 12,
        label: "Dec",
      },
    ],
  },
  {
    value: "3m",
    label: "3M",
    intervals: [
      {
        minMonth: 1,
        maxMonth: 3,
        label: "Jan - Mrt",
      },
      {
        minMonth: 4,
        maxMonth: 6,
        label: "Apr - Jun",
      },
      {
        minMonth: 7,
        maxMonth: 9,
        label: "Jul - Sep",
      },
      {
        minMonth: 10,
        maxMonth: 12,
        label: "Okt - Dec",
      },
    ],
  },
  {
    value: "6m",
    label: "6M",
    intervals: [
      {
        minMonth: 1,
        maxMonth: 6,
        label: "Jan - Jun",
      },
      {
        minMonth: 7,
        maxMonth: 12,
        label: "Jul - Dec",
      },
    ],
  },
  {
    value: "1j",
    label: "1J",
    intervals: [
      {
        minMonth: 1,
        maxMonth: 12,
        label: "Jan - Dec",
      },
    ],
  },
  {
    value: "always",
    label: "Altijd",
    intervals: [
      {
        minMonth: 1,
        maxMonth: 12,
        label: "Jan - Dec",
      },
    ],
  },
];
