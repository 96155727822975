import { React, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import QRCode from "react-qr-code";
import { validateAuth } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const AuthPage = ({ email, secretUrl }) => {
  const ADMIN = 1;
  const RESET_PASSWORD = -1;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const authSchema = Yup.object().shape({
    code: Yup.string()
      .required("Verplicht")
      .min(6, "Vul een 6 cijferige code in")
      .max(6, "Vul een 6 cijferige code in"),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(authSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const result = await validateAuth(data.code, email);
      if (result.role === RESET_PASSWORD) {
        navigate(`/user/resetPassword`);
      } else if (result.role === ADMIN) {
        navigate("/users");
      } else {
        navigate("/dashboard");
      }
    } catch (e) {
      setError(e.message);
    } finally {
      sessionStorage.removeItem("user");
      setLoading(false);
    }
  };

  return (
    <form
      method="POST"
      className="bg-white inline-flex flex-col py-4 px-6 rounded-lg w-full max-w-lg"
      onSubmit={handleSubmit(onSubmit)}
    >
      {secretUrl ? (
        <>
          <h1 className="text-2xl text-black">Koppel een authenticatie app</h1>
          <QRCode value={secretUrl} size={200} className="mt-4" />
          <a
            href="https://www.microsoft.com/nl-nl/security/mobile-authenticator-app"
            target="_blank"
            rel="noreferrer"
            className="text-indigo-600 hover:text-indigo-700 mb-3"
          >
            Download hier de Microsoft Authenticator App
          </a>
        </>
      ) : (
        <h1 className="text-2xl text-black mb-3">
          Vul de code van de authenticatie app in
        </h1>
      )}
      {error && <span className="text-red-500 mt-3">{error}</span>}
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-wrap justify-between">
          <label htmlFor="code">Authenticatie code (6 cijfers):</label>
          {formState.errors.code && (
            <span className="text-red-500">
              {formState.errors.code.message}
            </span>
          )}
        </div>
        <input
          type="number"
          name="code"
          className="bg-grey-accent rounded-md p-2 w-full"
          autoFocus
          {...register("code")}
        />
      </div>
      {loading ? (
        <AiOutlineLoading3Quarters className="animate-spin text-3xl mt-3" />
      ) : (
        <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-2 px-10 rounded inline-block mt-4 self-start">
          Check code
        </button>
      )}
    </form>
  );
};

export default AuthPage;
