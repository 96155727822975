import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";

const NavbarFrontPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const shouldShowDashboardLink =
    location.pathname !== "/login" && location.pathname !== "/register";

  return (
    <header className="fixed inset-x-0 top-0 z-50 overflow-x-hidden bg-white shadow">
      <nav
        aria-label="Global"
        className="flex items-center justify-between px-6 py-3 lg:px-8 max-w-7xl m-auto"
      >
        <div className="flex lg:flex-1">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="-m-1.5 p-1.5"
          >
            <img alt="" src={Logo} className="h-8 w-auto" />
          </button>
        </div>
        {shouldShowDashboardLink && (
          <button
            onClick={() => {
              navigate("/dashboard");
            }}
            className="text-sm leading-6 cursor-pointer"
          >
            Dashboard <span aria-hidden="true">&rarr;</span>
          </button>
        )}
      </nav>
    </header>
  );
};

export default NavbarFrontPage;
