import axios from "axios";

export async function getBodyParts() {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/notes/bodyparts`;
  try {
    const response = await axios.post(
      dataUrl,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function getNotes(data) {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/notes`;
  try {
    const response = await axios.post(dataUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function saveNote(data) {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/notes/save`;
  try {
    const response = await axios.post(dataUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function deleteNote(data) {
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/notes/delete`;
  try {
    const response = await axios.post(dataUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}
