import { React, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Input from "../components/Input";
import { resetPassword } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/ProtectedRoute";

const initialResetPassword = {
  password: "",
  passwordRepeat: "",
};

const ResetPasswordPage = () => {
  const ADMIN = 1;
  const RESET_PASSWORD = -1;

  const navigate = useNavigate();
  const auth = useAuth();

  const [error, setError] = useState("");

  useEffect(() => {
    if (auth) {
      if (auth.role !== RESET_PASSWORD) {
        if (auth.role === ADMIN) {
          navigate("/users");
        } else {
          navigate("/dashboard");
        }
      }
    }
  }, [auth]);

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Verplicht")
      .min(8, "Wachtwoord moet minimaal 8 karakters bevatten.")
      .matches(/\d+/, "Wachtwoord moet een nummer bevatten"),
    passwordRepeat: Yup.string()
      .required("Verplicht")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const onSubmit = async (data) => {
    setError(null);
    try {
      delete data.passwordRepeat;
      await resetPassword(data);
      navigate("/dashboard");
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className="flex justify-center w-full box-border">
      <Formik
        initialValues={initialResetPassword}
        validationSchema={resetPasswordSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ errors, touched }) => (
          <Form className="bg-white inline-flex flex-col gap-y-4 py-4 px-6 rounded-lg w-full max-w-lg">
            <h1 className="text-2xl text-black">Herstel wachtwoord</h1>
            {error && <span className="text-red-500">{error}</span>}
            <Input
              label="Wachtwoord"
              error={errors.password}
              touched={touched.password}
              type="password"
              name="password"
            />
            <Input
              label="Herhaal wachtwoord"
              error={errors.passwordRepeat}
              touched={touched.passwordRepeat}
              type="password"
              name="passwordRepeat"
            />
            <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-10 rounded inline-block mt-4 self-start">
              Login
            </button>
            <span>
              Heb je nog geen account? <br />{" "}
              <a className="text-indigo-700 underline" href="/register">
                Registreer
              </a>
            </span>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordPage;
