import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Graph from "../components/Graph";
import { GoArrowLeft } from "react-icons/go";
import { FaPlus, FaMinus } from "react-icons/fa";
import { getUser } from "../services/UsersService";
import { formatUser } from "../utils/users";
import { getUserGraph, saveData, deleteData } from "../services/DataService";
import { timeIntervals } from "../assets/data";
import {
  formatDateForInput,
  getAge,
  calculateDateDifference,
  calculateSumOfDates,
} from "../utils/date";
import Model from "../components/Model";
import Input from "../components/Input";
import * as Yup from "yup";
import { Formik, Form, FieldArray, Field } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const initialPopup = {
  visible: false,
  title: "",
  text: "",
  onConfirm: null,
  onCancel: null,
};

const GraphPage = () => {
  const { graphId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(() => {
    // Check if location.state and location.state.userData exist
    if (location.state && location.state.userData) {
      return location.state.userData;
    }
    // Fall back to sessionStorage if location.state.userData does not exist
    const userDataFromSession = sessionStorage.getItem("user");
    return userDataFromSession ? JSON.parse(userDataFromSession) : null;
  });
  const [allData, setAllData] = useState({});
  const [line, setLine] = useState(null);
  const [popup, setPopup] = useState(initialPopup);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showDataError, setShowDataError] = useState(false);
  const [edittedData, setEdittedData] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateDate = Yup.date().required("Date is required");

  const validateNumber = Yup.number()
    .typeError("Must be a number")
    .required("Value is required");

  const editDataSchema = Yup.object().shape({
    newData: Yup.array()
      .of(
        Yup.object().shape(
          allData[line]?.title === "Norm"
            ? {
                years: validateNumber,
                months: validateNumber,
                days: validateNumber,
                y: validateNumber,
              }
            : {
                x: validateDate,
                y: validateNumber,
              },
        ),
      )
      .required("New data is required"),
    allData: Yup.array()
      .of(
        Yup.object().shape(
          allData[line]?.title === "Norm"
            ? {
                id: validateNumber,
                years: validateNumber,
                months: validateNumber,
                days: validateNumber,
                y: validateNumber,
              }
            : {
                id: validateNumber,
                x: validateDate,
                y: validateNumber,
              },
        ),
      )
      .required("All data is required"),
  });

  function transformToAllData(data) {
    let transformedData = {};
    data.lines.forEach((line) => {
      if (line.title === "Norm") {
        transformedData[line.id] = {
          title: line.title,
          data: line.datapoints?.map((point) => {
            const date = calculateDateDifference(user.birthdate, point.x);
            return {
              id: point.id,
              years: date.years,
              months: date.months,
              days: date.days,
              y: point.y,
            };
          }),
        };
      } else {
        transformedData[line.id] = {
          title: line.title,
          data: line.datapoints?.map((point) => ({
            id: point.id,
            x: formatDateForInput(point.x),
            y: point.y,
          })),
        };
      }
    });
    return transformedData;
  }

  async function fetchGraph() {
    try {
      if (!user) {
        throw new Error("No user data");
      }

      const allDataFilter = {
        "data-width": 4,
        year: null,
        monthInterval: 0,
      };
      const data = {
        uuid: user.uuid,
        birthdate: user.birthdate,
        minMonth:
          timeIntervals[allDataFilter["data-width"]].intervals[
            allDataFilter.monthInterval
          ].minMonth,
        maxMonth:
          timeIntervals[allDataFilter["data-width"]].intervals[
            allDataFilter.monthInterval
          ].maxMonth,
        year: allDataFilter.year,
        graphId: graphId,
      };
      let result = await getUserGraph(data);
      const transformedData = transformToAllData(result);
      setAllData(transformedData);

      if (Object.keys(transformedData).length > 1) {
        setLine(Object.keys(transformedData)[1]);
      } else if (Object.keys(transformedData).length > 0) {
        setLine(Object.keys(transformedData)[0]);
      }
    } catch (error) {
      setError(error.message);
    }
  }

  function filterData(data) {
    return (
      data.filter((allDataEntry, index) => {
        const initialAllDataEntry = (allData[line].data || [])[index];
        if (allData[line]?.title === "Norm") {
          return (
            allDataEntry.years !== initialAllDataEntry?.years ||
            allDataEntry.months !== initialAllDataEntry?.months ||
            allDataEntry.days !== initialAllDataEntry?.days ||
            allDataEntry.y !== initialAllDataEntry?.y
          );
        } else {
          return (
            allDataEntry.x !== initialAllDataEntry?.x ||
            allDataEntry.y !== initialAllDataEntry?.y
          );
        }
      }) || []
    );
  }

  // Format the data of all lines such that all lines have data with the format {id, x, y} or {x, y}
  function formatData(changedAllData, newData) {
    function formatDate(years, months, days) {
      const adjustedYear = String(years + 1).padStart(4, "0");
      const adjustedMonth = String(months + 1).padStart(2, "0");
      const adjustedDay = String(days + 1).padStart(2, "0");
      return `${adjustedYear}-${adjustedMonth}-${adjustedDay}`;
    }

    if (allData[line]?.title === "Norm") {
      changedAllData.data = changedAllData.data.map((item) => {
        const { years, months, days } = item;
        const formattedDate = formatDate(years, months, days);
        // Set formatted date and remove years, months, days
        return {
          id: item.id,
          x: formattedDate,
          y: item.y,
        };
      });

      newData.data = newData.data.map((item) => {
        const { years, months, days } = item;
        const formattedDate = formatDate(years, months, days);
        // Set formatted date and remove years, months, days
        return {
          x: formattedDate,
          y: item.y,
        };
      });
    } else {
      changedAllData.data = changedAllData.data?.map((item) => {
        // Delete years, months, days
        return {
          id: item.id,
          x: item.x,
          y: item.y,
        };
      });
    }
  }

  function orderData(values) {
    return [...values.allData, ...values.newData].sort((a, b) => {
      if (allData[line]?.title === "Norm") {
        return (
          a.years - b.years ||
          a.months - b.months ||
          a.days - b.days ||
          a.y - b.y
        );
      }
      return a.x.localeCompare(b.x);
    });
  }

  async function handleOnSubmit(values, setSubmitting) {
    setError(null);
    setSuccess(null);
    setLoading(true);

    // Filter allData and format the data values
    let changedAllData = {
      lineId: line,
      data: filterData(values.allData),
    };
    let newData = {
      uuid: allData[line].title === "Norm" ? null : user.uuid,
      lineId: line,
      data: values.newData,
    };
    formatData(changedAllData, newData);

    // Returns the new ids of newData
    const ids = await handleSaveData({
      allData: changedAllData,
      newData: newData,
    });
    if (!ids) {
      return;
    }
    ids.forEach((id, index) => {
      newData.data[index].id = id;
      values.newData[index].id = id;
    });

    if (allData[line]?.title === "Norm") {
      // Changes norm dates (age) into current dates (age+birthdate)
      changedAllData.data.map((item) => {
        item.x = calculateSumOfDates(user.birthdate, item.x);
      });
      newData.data.map((item) => {
        item.x = calculateSumOfDates(user.birthdate, item.x);
      });
    }

    setEdittedData({
      [allData[line].title]: [...changedAllData.data, ...newData.data],
    });

    setAllData({
      ...allData,
      [line]: {
        ...allData[line],
        data: orderData(values),
      },
    });
    setSubmitting(false);
    setLoading(false);
  }

  async function handleSaveData(data) {
    try {
      const result = await saveData(data);
      setSuccess(result.message);
      return result;
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        navigate("/dashboard");
      } else {
        setError(e.message);
      }
      return false;
    }
  }

  async function handleDeleteData(values) {
    try {
      setError(null);
      setSuccess(null);

      const idsToDelete = values.allData
        .filter((item) => item.delete)
        .map((item) => item.id);

      if (idsToDelete.length === 0) {
        setError("Selecteer minimaal één meting om te verwijderen");
        return;
      }

      const data = { ids: idsToDelete };
      const result = await deleteData(data);
      setSuccess(result.message);

      // Update graph data
      setEdittedData({
        [allData[line].title]: idsToDelete.map((id) => ({ deleteId: id })),
      });

      // Update alldata
      const allDataCopy = { ...allData };
      allDataCopy[line].data = allDataCopy[line].data.filter(
        (item) => !idsToDelete.includes(item.id),
      );
      setAllData(allDataCopy);
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        navigate("/dashboard");
      } else {
        setError(e.message);
      }
    } finally {
      setPopup(initialPopup);
    }
  }

  function handleChangeLine(e, values) {
    const newLine = e.target.value;
    const edittedData = filterData(values.allData);
    if (edittedData.length > 0 || values.newData.length > 0) {
      setPopup({
        visible: true,
        title: "Weet je zeker dat je de huidige data wilt verlaten?",
        text: `De huidige aanpassingen voor de lijn ${allData[line].title} zullen direct ongedaan worden gemaakt.`,
        onCancel: () => {
          setPopup(initialPopup);
        },
        onConfirm: () => {
          setLine(newLine);
          setPopup(initialPopup);
        },
      });
    } else {
      setLine(newLine);
    }
  }

  useEffect(() => {
    if (!graphId) {
      navigate("/dashboard");
    }

    if (!location.state?.userData && !sessionStorage.getItem("user")) {
      async function fetchUser() {
        try {
          const user = await getUser();
          if (user === null) {
            navigate("/dashboard");
          }
          setUser(formatUser(user));
          return user.uuid;
        } catch (e) {
          if (e.status === 401 || e.status === 403) {
            navigate("/dashboard");
          } else {
            setError(e.message);
          }
        }
      }
      fetchUser();
    }
    window.history.replaceState({}, "");
  }, [location]);

  useEffect(() => {
    if (user) {
      fetchGraph();
    }
  }, [user]);

  useEffect(() => {
    setError(null);
    setSuccess(null);
  }, [line]);

  return (
    <>
      <div className="box-border">
        <div className="mb-4">
          <button
            className="mb-4 flex items-center gap-2"
            onClick={() => {
              navigate(`/dashboard`, { state: { userData: user } });
            }}
          >
            <GoArrowLeft />
            <span>Back to dashboard</span>
          </button>
          <h1 className="text-2xl">
            {user && user.firstname + " " + user.lastname}
          </h1>
          <span className="text-m">
            Leeftijd: {user && getAge(user.birthdate)}
          </span>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
          {user && (
            <Graph
              graphId={graphId}
              initialGraph={location.state?.graph}
              initialGraphFilter={location.state?.graphFilter}
              initialCachedData={location.state?.allData}
              edittedData={edittedData}
              user={user}
            />
          )}

          <Formik
            initialValues={{
              allData:
                allData[line]?.data?.map((item) => ({
                  id: item.id,
                  years: item.years ?? "",
                  months: item.months ?? "",
                  days: item.days ?? "",
                  x: item.x ?? "",
                  y: item.y,
                })) || [],
              newData: [],
            }}
            validationSchema={editDataSchema}
            onSubmit={(values, { setSubmitting }) =>
              handleOnSubmit(values, setSubmitting)
            }
            enableReinitialize={true}
          >
            {({ values, errors, touched, isSubmitting, submitCount }) => (
              <Form className="bg-white inline-flex flex-col gap-y-4 py-4 px-6 rounded-lg w-full max-w-lg shadow-lg">
                <div className="flex justify-between items-center">
                  <select
                    className="border border-gray-300 rounded-lg py-2 px-2"
                    value={line || ""}
                    onChange={(e) => handleChangeLine(e, values)}
                  >
                    {Object.keys(allData).map((line, index) => (
                      <option key={index} value={line}>
                        {allData[line].title}
                      </option>
                    ))}
                  </select>
                  <div className="flex gap-4">
                    {loading ? (
                      <AiOutlineLoading3Quarters className="animate-spin" />
                    ) : (
                      <button
                        className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-6 rounded text-sm"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => {
                          setShowDataError(true);
                        }}
                      >
                        Opslaan
                      </button>
                    )}
                    <button
                      className="text-red-500"
                      type="button"
                      onClick={() => {
                        setPopup({
                          visible: true,
                          title:
                            "Weet je zeker dat je deze meting wilt verwijderen?",
                          text: `Deze actie kan niet ongedaan worden gemaakt en zal direct worden uitgevoerd. Daarnaast worden eventuele metingen die nog niet zijn opgeslagen ook verwijderd.`,
                          onCancel: () => {
                            setPopup(initialPopup);
                          },
                          onConfirm: () => {
                            handleDeleteData(values);
                          },
                        });
                      }}
                    >
                      Verwijder
                    </button>
                  </div>
                </div>

                {(showDataError || errors.allData) &&
                  submitCount > 0 &&
                  (errors.newData || errors.allData) && (
                    <span className="text-red-500">
                      Vul alle datums en waardes goed in
                    </span>
                  )}
                {error && <span className="text-red-500">{error}</span>}
                {success && <span className="text-green-500">{success}</span>}

                <div className="w-full md:max-h-[500px] md:overflow-auto">
                  <table className="w-full">
                    <FieldArray name="newData">
                      {({ push, remove }) => (
                        <>
                          <thead>
                            {allData[line]?.title === "Norm" ? (
                              <tr>
                                <th className="py-2 text-start">Jaren</th>
                                <th className="py-2 text-start">Maanden</th>
                                <th className="py-2 text-start">Dagen</th>
                                <th className="py-2 text-start">Waarde</th>
                                <th className="py-2 text-start">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      push({
                                        years: "",
                                        months: "",
                                        days: "",
                                        y: "",
                                      });
                                      setShowDataError(false);
                                    }}
                                  >
                                    <FaPlus />
                                  </button>
                                </th>
                              </tr>
                            ) : (
                              <tr>
                                <th className="py-2 text-start">Datum</th>
                                <th className="py-2 text-start">Waarde</th>
                                <th className="py-2 text-start">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      push({ x: "", y: "" });
                                      setShowDataError(false);
                                    }}
                                  >
                                    <FaPlus />
                                  </button>
                                </th>
                              </tr>
                            )}
                          </thead>
                          <tbody
                            className={`${values.newData.length > 0 && "border-b-2 after:block after:h-2"}`}
                          >
                            {values.newData.map((_, i) => (
                              <tr key={i} className="items-center">
                                {allData[line]?.title === "Norm" ? (
                                  <>
                                    <td className="pr-4">
                                      <Input
                                        type="number"
                                        name={`newData.${i}.years`}
                                        className="input bg-grey-accent rounded-md p-2 block"
                                        error={
                                          submitCount > 0 &&
                                          errors.newData &&
                                          errors.newData[i]?.years
                                        }
                                        touched={
                                          submitCount > 0 &&
                                          touched.newData &&
                                          touched.newData[i]?.years
                                        }
                                        errorBorder={true}
                                      />
                                    </td>
                                    <td className="pr-4">
                                      <Input
                                        type="number"
                                        name={`newData.${i}.months`}
                                        className="input bg-grey-accent rounded-md p-2 block"
                                        error={
                                          submitCount > 0 &&
                                          errors.newData &&
                                          errors.newData[i]?.months
                                        }
                                        touched={
                                          submitCount > 0 &&
                                          touched.newData &&
                                          touched.newData[i]?.months
                                        }
                                        errorBorder={true}
                                      />
                                    </td>
                                    <td className="pr-4">
                                      <Input
                                        type="number"
                                        name={`newData.${i}.days`}
                                        className="input bg-grey-accent rounded-md p-2 block"
                                        error={
                                          submitCount > 0 &&
                                          errors.newData &&
                                          errors.newData[i]?.days
                                        }
                                        touched={
                                          submitCount > 0 &&
                                          touched.newData &&
                                          touched.newData[i]?.days
                                        }
                                        errorBorder={true}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <td className="pr-4">
                                    <Input
                                      type="date"
                                      name={`newData.${i}.x`}
                                      className="input bg-grey-accent rounded-md p-2 block"
                                      error={
                                        submitCount > 0 &&
                                        errors.newData &&
                                        errors.newData[i]?.x
                                      }
                                      touched={
                                        submitCount > 0 &&
                                        touched.newData &&
                                        touched.newData[i]?.x
                                      }
                                      errorBorder={true}
                                    />
                                  </td>
                                )}
                                <td className="pr-4">
                                  <Input
                                    type="number"
                                    name={`newData.${i}.y`}
                                    className="input bg-grey-accent rounded-md p-2 block"
                                    error={
                                      submitCount > 0 &&
                                      errors.newData &&
                                      errors.newData[i]?.y
                                    }
                                    touched={
                                      submitCount > 0 &&
                                      touched.newData &&
                                      touched.newData[i]?.y
                                    }
                                    errorBorder={true}
                                  />
                                </td>
                                <td onClick={() => remove(i)}>
                                  <FaMinus className="text-red-500 cursor-pointer" />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </>
                      )}
                    </FieldArray>
                    <tbody className="before:block before:h-2">
                      <FieldArray name="allData">
                        {() =>
                          values.allData.map((_, i) => (
                            <tr key={i}>
                              {allData[line]?.title === "Norm" ? (
                                <>
                                  <td className="pr-4">
                                    <Input
                                      type="number"
                                      name={`allData.${i}.years`}
                                      className="input bg-grey-accent rounded-md p-2 block"
                                      error={
                                        submitCount > 0 &&
                                        errors.allData &&
                                        errors.allData[i]?.years
                                      }
                                      touched={
                                        submitCount > 0 &&
                                        touched.allData &&
                                        touched.allData[i]?.years
                                      }
                                      errorBorder={true}
                                    />
                                  </td>
                                  <td className="pr-4">
                                    <Input
                                      type="number"
                                      name={`allData.${i}.months`}
                                      className="input bg-grey-accent rounded-md p-2 block"
                                      error={
                                        submitCount > 0 &&
                                        errors.allData &&
                                        errors.allData[i]?.months
                                      }
                                      touched={
                                        submitCount > 0 &&
                                        touched.allData &&
                                        touched.allData[i]?.months
                                      }
                                      errorBorder={true}
                                    />
                                  </td>
                                  <td className="pr-4">
                                    <Input
                                      type="number"
                                      name={`allData.${i}.days`}
                                      className="input bg-grey-accent rounded-md p-2 block"
                                      error={
                                        submitCount > 0 &&
                                        errors.allData &&
                                        errors.allData[i]?.days
                                      }
                                      touched={
                                        submitCount > 0 &&
                                        touched.allData &&
                                        touched.allData[i]?.days
                                      }
                                      errorBorder={true}
                                    />
                                  </td>
                                </>
                              ) : (
                                <td className="pr-4">
                                  <Input
                                    type="date"
                                    name={`allData.${i}.x`}
                                    className="input bg-grey-accent rounded-md p-2 block"
                                    error={
                                      submitCount > 0 &&
                                      errors.allData &&
                                      errors.allData[i]?.x
                                    }
                                    touched={
                                      submitCount > 0 &&
                                      touched.allData &&
                                      touched.allData[i]?.x
                                    }
                                    errorBorder={true}
                                  />
                                </td>
                              )}
                              <td className="pr-4">
                                <Input
                                  type="number"
                                  name={`allData.${i}.y`}
                                  className="input bg-grey-accent rounded-md p-2 block"
                                  error={
                                    submitCount > 0 &&
                                    errors.allData &&
                                    errors.allData[i]?.y
                                  }
                                  touched={
                                    submitCount > 0 &&
                                    touched.allData &&
                                    touched.allData[i]?.y
                                  }
                                  errorBorder={true}
                                />
                              </td>
                              <td>
                                <Field
                                  type="checkbox"
                                  name={`allData.${i}.delete`}
                                  className="w-5 h-5 text-indigo-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
                                />
                              </td>
                            </tr>
                          ))
                        }
                      </FieldArray>
                    </tbody>
                  </table>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Model {...popup} />
    </>
  );
};

export default GraphPage;
