import React, { useState, useEffect, useRef } from "react";
import DashboardVideo from "../assets/dashboard.mov";
import { TbRotate } from "react-icons/tb";
import Logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { GoGraph } from "react-icons/go";
import NavbarFrontPage from "../components/NavbarFrontPage";
import { FaPlay, FaPause } from "react-icons/fa";

export default function FrontPage() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(true);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPaused(false);
    } else {
      videoRef.current.pause();
      setIsPaused(true);
    }
  };

  useEffect(() => {
    const handleVideoEnd = () => {
      setIsPaused(true);
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener("ended", handleVideoEnd);

    // Cleanup the event listener on component unmount
    return () => {
      videoElement.removeEventListener("ended", handleVideoEnd);
    };
  }, []);

  return (
    <>
      <NavbarFrontPage />

      <div className="overflow-hidden bg-white py-24 sm:py-32 w-full">
        <section className="mx-auto max-w-7xl px-6 lg:px-8" id="dashboard">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg mt-10">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Follow your{" "}
                  <span className="inline-flex items-end">
                    Pro
                    <TbRotate
                      className="text-logo-red text-3xl mb-1"
                      style={{ strokeWidth: "3" }}
                    />
                    ress
                  </span>{" "}
                  <br />
                  bij TC4Athletes
                </p>

                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Bij TC4Athletes bieden we een dashboard waarmee je eenvoudig
                  jouw voortgang kunt volgen. Ons platform stelt sporters in
                  staat om prestaties in detail te analyseren, doelen te stellen
                  en trends te identificeren. Met real-time gegevens en
                  overzichtelijke rapportages krijg je waardevolle inzichten in
                  jouw ontwikkeling, wat bijdraagt aan een effectievere training
                  en betere resultaten.
                </p>
                <button
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-2 px-6 rounded inline-block mt-4 self-start"
                >
                  Ga naar dashboard
                </button>
              </div>
            </div>
            <div>
              <video
                ref={videoRef}
                loop
                muted
                webkit-playsinline="true"
                playsInline
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              >
                <source src={DashboardVideo} type="video/mp4" />
              </video>
              <button
                onClick={togglePlayPause}
                className="mt-6 font-medium text-blue-500 hover:text-blue-600 flex gap-2 items-center"
              >
                {isPaused ? (
                  <>
                    <FaPlay />
                    <span>Speel voorbeeld video af</span>
                  </>
                ) : (
                  <>
                    <FaPause />
                    <span>Pauzeer voorbeeld video</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </section>

        <section
          className="mx-auto max-w-7xl px-6 lg:px-8 mt-10 overflow-hidden"
          id="contact"
        >
          <div className="container py-10 mx-auto max-w-7xl mt-16 overflow-hidden">
            <h1 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl">
              Ontdek het <span className="text-indigo-500">Dashboard</span>
            </h1>

            <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 sm:grid-cols-2 xl:grid-cols-3">
              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl shadow-md">
                <span className="inline-block p-3 text-indigo-500 bg-indigo-100 rounded-full">
                  <GoGraph className="w-6 h-6" style={{ strokeWidth: "1" }} />
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize">
                  Krijg inzicht in je progressie
                </h1>

                <p className="text-gray-500">
                  Krijg inzicht in je voortgang met ons overzichtelijke
                  dashboard. Volg je prestaties in detail, vergelijk resultaten
                  en ontdek trends die je helpen bij het optimaliseren van je
                  trainingen.
                </p>
              </div>

              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl shadow-md">
                <span className="inline-block p-3 text-indigo-500 bg-indigo-100 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    />
                  </svg>
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize">
                  Selecteer de juiste periode
                </h1>

                <p className="text-gray-500">
                  Pas de weergave aan op basis van specifieke tijdsperioden om
                  gedetailleerde analyses te krijgen van je vooruitgang over
                  maanden, jaren of altijd. Hierdoor kun je gerichter werken aan
                  je doelen en prestaties verbeteren.
                </p>
              </div>

              <div className="flex flex-col items-center p-6 space-y-3 text-center bg-gray-100 rounded-xl shadow-md">
                <span className="inline-block p-3 text-indigo-500 bg-indigo-100 rounded-full">
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 30 30"
                    stroke="currentColor"
                  >
                    <path
                      d="M27.3633 7.08984H26.4844V6.21094C26.4844 4.75705 25.3015 3.57422 23.8477 3.57422H4.39453C2.94064 3.57422 1.75781 4.75705 1.75781 6.21094V21.1523H0.878906C0.393516 21.1523 0 21.5459 0 22.0312V23.7891C0 25.2429 1.18283 26.4258 2.63672 26.4258H27.3633C28.8172 26.4258 30 25.2429 30 23.7891V9.72656C30 8.27268 28.8172 7.08984 27.3633 7.08984ZM3.51562 6.21094C3.51562 5.72631 3.9099 5.33203 4.39453 5.33203H23.8477C24.3323 5.33203 24.7266 5.72631 24.7266 6.21094V7.08984H20.332C18.8781 7.08984 17.6953 8.27268 17.6953 9.72656V21.1523H3.51562V6.21094ZM1.75781 23.7891V22.9102H17.6953V23.7891C17.6953 24.0971 17.7489 24.3929 17.8465 24.668H2.63672C2.15209 24.668 1.75781 24.2737 1.75781 23.7891ZM28.2422 23.7891C28.2422 24.2737 27.8479 24.668 27.3633 24.668H20.332C19.8474 24.668 19.4531 24.2737 19.4531 23.7891V9.72656C19.4531 9.24193 19.8474 8.84766 20.332 8.84766H27.3633C27.8479 8.84766 28.2422 9.24193 28.2422 9.72656V23.7891Z"
                      fill="#2D3748"
                    />
                    <path
                      d="M24.7266 21.1523H22.9688C22.4834 21.1523 22.0898 21.5459 22.0898 22.0312C22.0898 22.5166 22.4834 22.9102 22.9688 22.9102H24.7266C25.212 22.9102 25.6055 22.5166 25.6055 22.0312C25.6055 21.5459 25.212 21.1523 24.7266 21.1523Z"
                      fill="#4299E1"
                    />
                    <path
                      d="M23.8477 12.3633C24.3331 12.3633 24.7266 11.9698 24.7266 11.4844C24.7266 10.999 24.3331 10.6055 23.8477 10.6055C23.3622 10.6055 22.9688 10.999 22.9688 11.4844C22.9688 11.9698 23.3622 12.3633 23.8477 12.3633Z"
                      fill="#4299E1"
                    />
                  </svg>
                </span>
                <h1 className="text-xl font-semibold text-gray-700 capitalize">
                  Bekijk op je computer en mobiel
                </h1>
                <p className="text-gray-500">
                  Het dashboard is volledig geoptimaliseerd voor zowel desktop
                  als mobiele apparaten. Of je nu onderweg bent of op kantoor
                  werkt, je hebt altijd toegang tot je gegevens en kunt je
                  voortgang overal bijhouden.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white mt-10">
          <div className="container px-6 py-12 max-w-7xl mx-auto">
            <div>
              <p className="font-medium text-indigo-500">Contact</p>

              <h1 className="mt-1 text-2xl font-semibold text-gray-800 md:text-3xl">
                Neem contact op
              </h1>
            </div>

            <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 lg:grid-cols-4">
              <div>
                <span className="inline-block p-3 text-indigo-500 rounded-full bg-indigo-100/80">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </span>

                <h2 className="mt-4 text-lg font-medium text-gray-800">
                  Email
                </h2>
                <a
                  href="mail:erick1963@gmail.com"
                  className="mt-2 text-indigo-500"
                >
                  Erick1963@gmail.com
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer className="bg-white">
        <div className="container flex flex-col items-center justify-between p-6 mx-auto space-y-4 sm:space-y-0 sm:flex-row max-w-7xl">
          <a href="#">
            <img className="w-auto h-7" src={Logo} alt="" />
          </a>

          <p className="text-sm text-gray-600">
            © Copyright {new Date().getFullYear()}. All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
