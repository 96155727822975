import React, { useState, useEffect } from "react";
import { timeIntervals } from "../assets/data";
import { useAuth } from "../components/ProtectedRoute";
import { useNavigate } from "react-router-dom";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { getUserGraph } from "../services/DataService";
import { formatGraph } from "../utils/graphs";
import {
  getCurrentMonth,
  getCurrentQuarter,
  getCurrentHalfYear,
} from "../utils/date";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
);

const Graph = ({
  graphId,
  initialGraph,
  initialGraphFilter,
  initialCachedData,
  edittedData,
  graphIndex,
  fullScreen,
  fullscreenGraph,
  setFullscreenGraph,
  manageData,
  user,
}) => {
  const ADMIN = 1;
  const auth = useAuth();
  const navigate = useNavigate();

  const [graph, setGraph] = useState(initialGraph);
  const [graphFilter, setGraphFilter] = useState(initialGraphFilter);
  const [allData, setAllData] = useState(initialCachedData);
  const [error, setError] = useState(null);

  function addAllData(graph) {
    setAllData((prevAllData) => {
      const filterKey = JSON.stringify(graphFilter);
      let currentAllData = { ...prevAllData };
      return { ...currentAllData, [filterKey]: graph.data?.datasets };
    });
  }

  function setCachedData(givenData) {
    if (givenData && givenData[JSON.stringify(graphFilter)]) {
      let newGraph = { ...graph };
      newGraph.data.datasets = givenData[JSON.stringify(graphFilter)];
      setGraph(newGraph);
      return true;
    }
    return false;
  }

  async function fetchGraph(graphId) {
    try {
      setError(null);
      if (!user) {
        throw new Error("No user data");
      }
      if (!graphFilter) {
        throw new Error("No graph filter data");
      }
      if (!graphId && !graph) {
        throw new Error("No graph data");
      }

      // Check if data is already fetched
      if (setCachedData(allData)) {
        return;
      }

      const data = {
        uuid: user.uuid,
        birthdate: user.birthdate,
        minMonth:
          timeIntervals[graphFilter["data-width"]].intervals[
            graphFilter.monthInterval
          ].minMonth,
        maxMonth:
          timeIntervals[graphFilter["data-width"]].intervals[
            graphFilter.monthInterval
          ].maxMonth,
        year: graphFilter.year,
        graphId: graphId ? graphId : graph.id,
      };
      let result = await getUserGraph(data);
      result = formatGraph(result);
      setGraph(result);
      addAllData(result);
    } catch (error) {
      setError(error.message);
    }
  }

  function setMonthInterval(oldGraph, newGraph) {
    const MONTHS = 0;
    const QUARTERS = 1;
    const HALFYEARS = 2;
    const YEARS = 3;

    if (
      oldGraph["data-width"] === MONTHS &&
      newGraph["data-width"] === QUARTERS
    ) {
      newGraph.monthInterval = Math.floor(oldGraph.monthInterval / 3);
    } else if (
      oldGraph["data-width"] === MONTHS &&
      newGraph["data-width"] === HALFYEARS
    ) {
      newGraph.monthInterval = Math.floor(oldGraph.monthInterval / 6);
    } else if (
      oldGraph["data-width"] === QUARTERS &&
      newGraph["data-width"] === HALFYEARS
    ) {
      newGraph.monthInterval = Math.floor(oldGraph.monthInterval / 2);
    } else if (newGraph["data-width"] >= YEARS) {
      newGraph.monthInterval = 0;
    } else if (oldGraph["data-width"] > newGraph["data-width"]) {
      if (newGraph["data-width"] === YEARS) {
        newGraph.monthInterval = 0;
      } else if (newGraph["data-width"] === HALFYEARS) {
        newGraph.monthInterval = getCurrentHalfYear();
      } else if (newGraph["data-width"] === QUARTERS) {
        newGraph.monthInterval = getCurrentQuarter();
      } else if (newGraph["data-width"] === MONTHS) {
        newGraph.monthInterval = getCurrentMonth();
      }
    }
  }

  function handleOnChange(e) {
    setError(null);
    let { name, value } = e.target;
    if (name.includes("data-width")) {
      name = "data-width";
    }
    value = parseInt(value);

    let newGraph = { ...graphFilter };
    switch (name) {
      case "data-width":
        const oldGraph = { ...newGraph };
        // Set year back to current year if always was selected
        if (newGraph["data-width"] === timeIntervals.length - 1) {
          newGraph.year =
            graph.years === null || new Date().getFullYear() in graph.years
              ? new Date().getFullYear()
              : graph.years[0];
        }
        newGraph["data-width"] = value;
        // Set year to null to get all data for the graph
        if (newGraph["data-width"] === timeIntervals.length - 1) {
          newGraph.year = null;
        }
        setMonthInterval(oldGraph, newGraph);
        break;
      case "year":
        newGraph.year = value;
        break;
      case "months":
        newGraph.monthInterval = value;
        break;
      default:
        break;
    }
    setGraphFilter(newGraph);
  }

  useEffect(() => {
    if (!initialGraphFilter) {
      // Set initial filter if not given
      const month = getCurrentMonth();
      setGraphFilter({
        "data-width": 0,
        year: new Date().getFullYear(),
        monthInterval: month - 1,
      });
    }
    if (initialGraph) {
      // Set chached data for the initial graph
      addAllData(initialGraph);
    }
  }, []);

  useEffect(() => {
    if ((!initialGraph || allData) && graphFilter) {
      // Only fetch data when the allData is set or there is no initial graph to prevent unnecessary fetching
      fetchGraph(graphId);
    }
  }, [graphFilter]);

  useEffect(() => {
    if (allData) {
      fetchGraph(graphId);
    }
  }, [allData]);

  useEffect(() => {
    function pointInRange(point, cacheKey) {
      const pointDate = new Date(point.x);
      const minMonth =
        timeIntervals[cacheKey["data-width"]].intervals[cacheKey.monthInterval]
          .minMonth;
      const maxMonth =
        timeIntervals[cacheKey["data-width"]].intervals[cacheKey.monthInterval]
          .maxMonth;
      const year = cacheKey.year;

      if (year === null) {
        return true;
      }

      const pointYear = pointDate.getFullYear();
      const pointMonth = pointDate.getMonth() + 1;

      if (
        pointYear === year &&
        pointMonth >= minMonth &&
        pointMonth <= maxMonth
      ) {
        return true;
      }

      return false;
    }

    if (edittedData) {
      const updatedAllData = { ...allData };

      Object.keys(updatedAllData).forEach((key) => {
        const cacheInstance = [...updatedAllData[key]];

        const lineIndex = cacheInstance.findIndex((line) => {
          return line.label === Object.keys(edittedData)[0];
        });

        if (lineIndex !== -1) {
          const line = { ...cacheInstance[lineIndex] };

          edittedData[line.label].forEach((newPoint) => {
            if (newPoint.deleteId && line.data) {
              line.data = line.data.filter(
                (point) => point.id !== newPoint.deleteId,
              );
              return;
            }

            const pointIndex = line.data?.findIndex(
              (point) => point.id === newPoint.id,
            );
            const isInRange = pointInRange(newPoint, JSON.parse(key));

            if (pointIndex === -1 || pointIndex === undefined) {
              if (isInRange) {
                if (!line.data) {
                  line.data = [];
                }
                line.data.push(newPoint);
              }
            } else {
              if (!isInRange) {
                line.data.splice(pointIndex, 1);
              } else {
                line.data[pointIndex] = newPoint;
              }
            }

            // Update the years for the filters in the graph
            const date = new Date(newPoint.x);
            const year = date.getFullYear();
            if (graph.years && !graph.years.includes(year)) {
              graph.years = [...graph.years, year];
              graph.years.sort();
            }
          });

          line.data?.sort((a, b) => {
            const result = a.x.localeCompare(b.x);
            if (result !== 0) {
              return result;
            }
            return a.y - b.y;
          });
          cacheInstance[lineIndex] = line;
          updatedAllData[key] = cacheInstance;
        }
      });
      setAllData(updatedAllData);
    }
  }, [edittedData]);

  const dynamicWidth = `calc(100% / ${timeIntervals.length})`;

  return (
    <div
      className={`bg-white p-2 rounded-xl shadow-lg ${
        fullScreen && fullscreenGraph === graphIndex && "col-span-2 order-first"
      }`}
      key={graphIndex}
    >
      {error && <div className="text-red-500">{error}</div>}
      {graph && (
        <>
          <div className="flex gap-x-2 gap-y-4 items-center pt-2 pb-4 flex-wrap">
            <h1 className="order-1 font-medium">{graph.title}</h1>
            <form
              className="flex gap-4 items-center justify-between order-3 w-full flex-wrap"
              onChange={(e) => {
                handleOnChange(e, graph.id);
              }}
            >
              <div className="p-1 grow bg-grey-accent rounded-lg">
                <div
                  className="relative items-center justify-center w-auto grid"
                  style={{
                    gridTemplateColumns: `repeat(${timeIntervals.length}, 1fr)`,
                  }}
                >
                  {timeIntervals.map((interval, timeIndex) => (
                    <div
                      key={timeIndex}
                      className="flex-grow flex items-center justify-center"
                    >
                      <input
                        id={`data-width-${graphIndex}-${timeIndex}`}
                        type="radio"
                        value={timeIndex}
                        name={`data-width-${graphIndex}`}
                        className="hidden"
                        checked={
                          graphFilter && graphFilter["data-width"] === timeIndex
                        }
                        readOnly
                      />
                      <label
                        htmlFor={`data-width-${graphIndex}-${timeIndex}`}
                        className={`block rounded-lg py-1 px-2 cursor-pointer w-full h-full text-center z-[1] text-sm transition ${
                          graphFilter && graphFilter["data-width"] === timeIndex
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {interval.label}
                      </label>
                    </div>
                  ))}
                  <div
                    className={`absolute top-0 left-0 h-full bg-indigo-600 z-[0] rounded-md transition-transform duration-300 shadow`}
                    style={{
                      width: dynamicWidth,
                      transform: `translateX(calc(100% * ${graphFilter["data-width"]}))`,
                    }}
                  />
                </div>
              </div>

              {graphFilter &&
                graphFilter[`data-width`] < timeIntervals.length - 1 && (
                  <div>
                    <select
                      name="year"
                      value={graphFilter.year}
                      onChange={(e) => handleOnChange(e, graph.id)}
                    >
                      {graph.years &&
                        graph.years.map((year, i) => (
                          <option value={year} key={i}>
                            {year}
                          </option>
                        ))}
                    </select>

                    <select
                      name="months"
                      value={graphFilter.monthInterval}
                      onChange={(e) => handleOnChange(e, graph.id)}
                    >
                      {timeIntervals[graphFilter["data-width"]].intervals &&
                        timeIntervals[graphFilter["data-width"]].intervals.map(
                          (interval, i) => (
                            <option value={i} key={i}>
                              {interval.label}
                            </option>
                          ),
                        )}
                    </select>
                  </div>
                )}
            </form>
            <div className="flex gap-x-4 items-center justify-between ml-auto order-2">
              {manageData && auth && auth.role === ADMIN && (
                <button
                  className="underline text-sm"
                  onClick={() => {
                    navigate(`/graph/${graph.id}`, {
                      state: { graph, graphFilter, allData, userData: user },
                    });
                  }}
                >
                  Beheer data
                </button>
              )}
              {fullScreen && (
                <button
                  className="hidden sm:block"
                  onClick={() => {
                    setFullscreenGraph(
                      fullscreenGraph === graphIndex ? null : graphIndex,
                    );
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {fullscreenGraph === graphIndex ? (
                    <AiOutlineFullscreenExit className="text-2xl" />
                  ) : (
                    <AiOutlineFullscreen className="text-2xl" />
                  )}
                </button>
              )}
            </div>
          </div>
          <div
            className={`max-h-screen h-[300px] relative ${
              fullscreenGraph === graphIndex && fullScreen && "h-[500px]"
            }`}
          >
            {graph.data &&
              graph.data.datasets.every((dataset) => dataset.data === null) && (
                <>
                  <div className="absolute h-full w-full bg-grey-bg opacity-50"></div>
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    Geen data beschikbaar
                  </span>
                </>
              )}

            <Line data={graph.data} options={graph.options} />
          </div>
        </>
      )}
    </div>
  );
};

export default Graph;
