import axios from "axios";

export async function getCSRFToken() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/token/csrf`,
      { withCredentials: true },
    );
    axios.defaults.headers.post["x-csrf-token"] = response.data.CSRFToken;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}

export async function verifyToken() {
  try {
    const authenticationUrl = `${process.env.REACT_APP_API_BASE_URL}/token/verify`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        "x-csrf-token": axios.defaults.headers.post["x-csrf-token"],
      },
      withCredentials: true,
    };
    const response = await axios.post(authenticationUrl, {}, options);
    return response.data.message;
  } catch (error) {
    throw {
      status: error.response?.status || 500,
      message: error.response?.data.message || "Internal server error",
    };
  }
}
