export function formatGraph(graph) {
  const data = {
    datasets: graph.lines.map((line) => ({
      label: line.title,
      data: line.datapoints,
      fill: false,
      borderColor: line.color,
      tension: 0.1,
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    plugins: {
      tooltip: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "d MMM yyyy", // Format for the tooltip
          displayFormats: {
            day: "d MMM yyyy", // Format for the x-axis labels
          },
        },
      },
      y: {
        title: {
          display: true,
          text: graph.ylabel,
        },
      },
    },
  };

  return {
    id: graph.id,
    title: graph.title,
    years: graph.years,
    data,
    options,
  };
}
