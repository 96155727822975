import React from "react";
import { PiUsersThree, PiUser } from "react-icons/pi";
import { LuLayoutDashboard } from "react-icons/lu";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./ProtectedRoute";
import Logo from "../assets/logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const getLinkClasses = (path) => {
    if (path === "/users") {
      return location.pathname === path ||
        (location.pathname.startsWith(path.slice(0, 5)) &&
          location.pathname !== "/user")
        ? "text-indigo-600"
        : "text-black";
    }
    return location.pathname === path ? "text-indigo-600" : "text-black";
  };

  return (
    <nav className="bg-white absolute top-0 left-0 w-full box-border border-b-2 border-grey-accent">
      <ul className="max-w-[1200px] mx-auto flex gap-x-8 flex-wrap px-2 sm:px-4">
        <li
          className={`py-4 cursor-pointer flex items-center gap-1`}
          onClick={() => {
            sessionStorage.setItem("active", "Dashboard");
            navigate("/");
          }}
        >
          <img src={Logo} alt="Logo" className="h-8" />
        </li>
        <li
          className={`py-4 cursor-pointer flex items-center gap-1 ${getLinkClasses("/dashboard")}`}
          onClick={() => {
            sessionStorage.setItem("active", "Dashboard");
            navigate("/dashboard");
          }}
        >
          <LuLayoutDashboard className="text-xl" />
          <span className="hidden sm:block">Dashboard</span>
        </li>
        {auth?.role === 1 && (
          <li
            className={`py-4 cursor-pointer flex items-center gap-1 ${getLinkClasses("/users")}`}
            onClick={() => {
              navigate("/users");
            }}
          >
            <PiUsersThree className="text-xl" />
            <span className="hidden sm:block">Gebruikers</span>
          </li>
        )}
        <li
          className={`py-4 cursor-pointer ml-auto flex items-center gap-1 ${getLinkClasses("/user")}`}
          onClick={() => {
            sessionStorage.removeItem("user");
            navigate("/user");
          }}
        >
          <PiUser className="text-xl" />
          <span className="hidden sm:block">Profiel</span>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
