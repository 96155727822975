export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

export function formatDateForInput(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function getCurrentMonth() {
  const today = new Date();
  return today.getMonth();
}

export function getCurrentQuarter() {
  const today = new Date();
  return Math.floor(today.getMonth() / 3);
}

export function getCurrentHalfYear() {
  const today = new Date();
  return Math.floor(today.getMonth() / 6);
}

export function calculateDateDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  let years = end.getFullYear() - start.getFullYear();
  let months = end.getMonth() - start.getMonth();
  let days = end.getDate() - start.getDate();

  if (days < 0) {
    months -= 1;
    const prevMonth = new Date(end.getFullYear(), end.getMonth(), 0);
    days += prevMonth.getDate();
  }

  if (months < 0) {
    years -= 1;
    months += 12;
  }

  return { years, months, days };
}

export function calculateSumOfDates(birthDate, date) {
  const birth = new Date(birthDate);
  const date2 = new Date(date);

  let years = birth.getFullYear() + date2.getFullYear() - 1;
  let months = birth.getMonth() + date2.getMonth() + 1;
  let days = birth.getDate() + date2.getDate() - 1;

  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  while (days > daysInMonth(years, months - 1)) {
    days -= daysInMonth(years, months - 1);
    months += 1;
  }

  while (months > 12) {
    months -= 12;
    years += 1;
  }

  return (
    String(years).padStart(4, "0") +
    "-" +
    String(months).padStart(2, "0") +
    "-" +
    String(days).padStart(2, "0")
  );
}

export function getElementsDate(date) {
  date = date.split("-");
  return {
    years: date[0],
    months: date[1],
    days: date[2].split("T")[0],
  };
}
