import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getCSRFToken, verifyToken } from "../services/TokenService";
import Navbar from "./Navbar";

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

const ProtectedRoute = ({ children, admin = false }) => {
  const ADMIN = 1;
  const RESET_PASSWORD = -1;
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    async function verify() {
      let fetchedPayload = null;
      try {
        await getCSRFToken();
        fetchedPayload = await verifyToken();
        if (fetchedPayload.role === RESET_PASSWORD) {
          navigate(`/user/resetPassword`);
          return;
        }
        if (admin) {
          if (fetchedPayload.role !== ADMIN) {
            navigate("/dashboard");
            return;
          }
        }
      } catch (e) {
        navigate("/login");
        return;
      } finally {
        setLoading(false);
        setPayload(fetchedPayload);
      }
    }

    verify();
  }, [location.pathname]);

  if (!loading) {
    return (
      <AuthContext.Provider value={payload}>
        <Navbar />
        <div className="max-w-[1200px] pt-20 m-auto px-2 sm:px-4">
          {children}
        </div>
      </AuthContext.Provider>
    );
  }
};

export default ProtectedRoute;
