import { React, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Input from "../components/Input";
import { getUser, updateUser } from "../services/UsersService";
import { useNavigate } from "react-router-dom";
import { getAge, formatDate } from "../utils/date";
import { GoArrowLeft } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { deleteUser } from "../services/UsersService";
import { temporaryPassword, logoutUser } from "../services/AuthService";
import Model from "../components/Model";
import { useAuth } from "../components/ProtectedRoute";
import { PiSignOut } from "react-icons/pi";
import { formatUser } from "../utils/users";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

// Null values are optional when a user has created an account
const initialUser = {
  firstname: "",
  lastname: "",
  phone: undefined,
  birthdate: undefined,
  city: "",
  zipcode: "",
  street: "",
  houseNumber: "",
  serialNumber: undefined,
  heartRate: undefined,
  bmi: undefined,
  lowerPressure: undefined,
  upperPressure: undefined,
};

const initialPopup = {
  visible: false,
  title: "",
  text: "",
  onConfirm: null,
  onCancel: null,
};

const EditUserPage = () => {
  const ADMIN = 1;

  const { uuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState(initialUser);
  const [editUser, setEditUser] = useState(false);
  const [popup, setPopup] = useState(initialPopup);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUser() {
      try {
        const user = await getUser(uuid);
        if (user === null) {
          navigate("/users");
        }
        setUser(formatUser(user));
      } catch (e) {
        if (e.status === 401 || e.status === 403) {
          navigate("/dashboard");
        } else {
          setError(e.message);
        }
      }
    }

    if (location.state && location.state.userData) {
      setUser(formatUser(location.state.userData));
    } else if (sessionStorage.getItem("user")) {
      setUser(formatUser(JSON.parse(sessionStorage.getItem("user"))));
    } else {
      fetchUser();
    }
  }, [location]);

  const editUserSchema = Yup.object().shape({
    firstname: Yup.string().required("Verplicht"),
    lastname: Yup.string().required("Verplicht"),
    birthdate: Yup.date("Vul een geldige datum in")
      .required("Verplicht")
      .min(new Date(Date.UTC(1900, 0, 1)), "Datum moet na 1900 zijn")
      .max(new Date(), "Geboortedatum kan niet in de toekomst liggen"),
    serialNumber: Yup.number().nullable(),
    heartRate: Yup.number().nullable(),
    bmi: Yup.number().nullable(),
    lowerPressure: Yup.number().nullable(),
    upperPressure: Yup.number().nullable(),
  });

  const transformEmptyStringsToNull = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        value === "" ? null : value,
      ]),
    );
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      data = {
        ...transformEmptyStringsToNull(data),
        uuid: uuid,
      };
      const result = await updateUser(data);
      setSuccess(result);
      setEditUser(false);
      setUser(data);
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        navigate("/dashboard");
      } else {
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  async function handleResetPassword() {
    setError(null);
    try {
      const password = await temporaryPassword(user.uuid);
      setPopup({
        visible: true,
        title: "Het wachtwoord is succesvol vervangen.",
        text: `De gebruiker kan binnen 3 uur inloggen met dit tijdelijke wachtwoord: ${password}`,
        onCancel: () => {
          setPopup(initialPopup);
        },
      });
    } catch (e) {
      setPopup(initialPopup);
      setError(e.message);
    }
  }

  async function handleDeleteUser() {
    try {
      await deleteUser(user.uuid);
      navigate("/users");
    } catch (e) {
      setError(e.message);
    } finally {
      sessionStorage.removeItem("user");
    }
    setPopup(initialPopup);
  }

  async function handleLogout() {
    try {
      await logoutUser();
      navigate("/login");
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <>
      <div className="flex justify-center box-border">
        <div className="bg-white inline-flex flex-col py-4 px-2 sm:px-6 rounded-lg w-full max-w-xl mb-12">
          {auth && auth.role === ADMIN && (
            <button
              className="flex gap-1 items-center mb-4"
              onClick={() => navigate("/users")}
            >
              <GoArrowLeft />
              Overzicht gebruikers
            </button>
          )}
          {error && <span className="text-red-500">{error}</span>}
          {success && <span className="text-green-500">{success}</span>}
          {editUser ? (
            <>
              <Formik
                initialValues={user}
                validationSchema={editUserSchema}
                onSubmit={(values) => onSubmit(values)}
              >
                {({ errors, touched }) => (
                  <Form className="inline-flex flex-col gap-y-4">
                    <button
                      className="underline self-start flex items-center gap-1"
                      onClick={() => {
                        setEditUser(false);
                      }}
                    >
                      <IoClose className="text-xl" /> Sluit aanpassen
                    </button>

                    <div className="grid grid-cols-2 w-full gap-4">
                      <Input
                        label="Voornaam"
                        error={errors.firstname}
                        touched={touched.firstname}
                        type="text"
                        name="firstname"
                      />
                      <Input
                        label="Achternaam"
                        error={errors.lastname}
                        touched={touched.lastname}
                        type="text"
                        name="lastname"
                      />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
                      <Input
                        label="E-mail"
                        error={errors.email}
                        touched={touched.email}
                        type="email"
                        name="email"
                      />
                      <Input
                        label="Telefoonnummer"
                        error={errors.phone}
                        touched={touched.phone}
                        type="tel"
                        name="phone"
                      />
                      <Input
                        label="Geboortedatum"
                        error={errors.birthdate}
                        touched={touched.birthdate}
                        type="date"
                        name="birthdate"
                      />
                    </div>
                    <div className="py-8 flex flex-col gap-4">
                      <div className="grid grid-cols-2 w-full gap-4">
                        <Input
                          label="Woonplaats"
                          error={errors.city}
                          touched={touched.city}
                          type="text"
                          name="city"
                        />
                        <Input
                          label="Postcode"
                          error={errors.zipcode}
                          touched={touched.zipcode}
                          type="text"
                          name="zipcode"
                        />
                      </div>
                      <div className="grid grid-cols-2 w-full gap-4">
                        <Input
                          label="Straatnaam"
                          error={errors.street}
                          touched={touched.street}
                          type="text"
                          name="street"
                        />
                        <Input
                          label="Huisnummer"
                          error={errors.houseNumber}
                          touched={touched.houseNumber}
                          type="text"
                          name="houseNumber"
                        />
                      </div>
                    </div>
                    {auth.role === ADMIN && (
                      <>
                        <span className="text-gray-500">Optioneel:</span>
                        <div className="grid grid-cols-2 w-full gap-4">
                          <Input
                            label="Volgnummer"
                            error={errors.serialNumber}
                            touched={touched.serialNumber}
                            type="number"
                            name="serialNumber"
                          />
                          <Input
                            label="Hartslag rust"
                            error={errors.heartRate}
                            touched={touched.heartRate}
                            type="number"
                            name="heartRate"
                          />
                        </div>
                        <div className="grid grid-cols-2 w-full gap-4">
                          <Input
                            label="BMI"
                            error={errors.bmi}
                            touched={touched.bmi}
                            type="number"
                            name="bmi"
                          />
                          <Input
                            label="Onderdruk"
                            error={errors.lowerPressure}
                            touched={touched.lowerPressure}
                            type="number"
                            name="lowerPressure"
                          />
                        </div>
                        <div className="grid grid-cols-2 w-full gap-4">
                          <Input
                            label="Bovendruk"
                            error={errors.upperPressure}
                            touched={touched.upperPressure}
                            type="number"
                            name="upperPressure"
                          />
                        </div>
                      </>
                    )}
                    <div className="flex justify-between items-center">
                      {loading ? (
                        <AiOutlineLoading3Quarters className="animate-spin text-3xl mt-3" />
                      ) : (
                        <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded inline-block mt-2 self-start">
                          Opslaan
                        </button>
                      )}
                      {auth &&
                        !loading &&
                        auth.role === ADMIN &&
                        auth.uuid !== user.uuid && (
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded inline-block mt-2 self-start"
                            onClick={(e) => {
                              e.preventDefault();
                              setPopup({
                                visible: true,
                                title: "Verwijder gebruiker",
                                text: "Weet je zeker dat je deze gebruiker wilt verwijderen? Alle data wordt verwijderd en deze actie valt niet terug te draaien.",
                                onCancel: () => {
                                  setPopup(initialPopup);
                                },
                                onConfirm: () => {
                                  handleDeleteUser();
                                },
                              });
                            }}
                          >
                            Verwijder gebruiker
                          </button>
                        )}
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div className="flex flex-col">
              <div className="flex items-center">
                <h1 className="text-2xl text-black">
                  {user && user.firstname + " " + user.lastname}
                </h1>
              </div>
              <div className="flex flex-col gap-y-1 mt-3 mb-6">
                <span>E-mail: {user.email && user.email}</span>
                <span>
                  Geboortedatum:{" "}
                  {user.birthdate
                    ? `${formatDate(user.birthdate)} (${getAge(user.birthdate)})`
                    : "-"}
                </span>
                <div className="flex flex-col gap-y-1 my-4">
                  <span>Plaats: {user.city ? user.city : "-"}</span>
                  <span>Postcode: {user.zipcode ? user.zipcode : "-"}</span>
                  <span>
                    Adres:{" "}
                    {user.street && user.houseNumber
                      ? user.street + " " + user.houseNumber
                      : "-"}
                  </span>
                </div>
                <span>
                  Volgnummer: {user.serialNumber ? user.serialNumber : "-"}
                </span>
                <span>
                  Hartslag in rust: {user.heartRate ? user.heartRate : "-"}
                </span>
                <span>BMI: {user.bmi ? user.bmi : "-"}</span>
                <span>
                  Bloeddruk (onderdruk):{" "}
                  {user.lowerPressure ? user.lowerPressure : "-"}
                </span>
                <span>
                  Bloeddruk (bovendruk):{" "}
                  {user.upperPressure ? user.upperPressure : "-"}
                </span>
              </div>
              <div className="flex items-center justify-between gap-x-4">
                <button
                  className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded inline-block self-start"
                  onClick={() => {
                    setSuccess(null);
                    setError(null);
                    setEditUser(true);
                  }}
                >
                  Aanpassen
                </button>
                {user.uuid &&
                auth &&
                auth.role === ADMIN &&
                auth.uuid !== user.uuid ? (
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded inline-block self-start"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopup({
                        visible: true,
                        title: "Herstel wachtwoord",
                        text: "Weet je zeker dat je het wachtwoord van deze gebruiker wilt herstellen? Hiermee vervalt het oude wachtwoord.",
                        onCancel: () => {
                          setPopup(initialPopup);
                        },
                        onConfirm: () => {
                          handleResetPassword();
                        },
                      });
                    }}
                  >
                    Herstel wachtwoord
                  </button>
                ) : (
                  user.uuid && (
                    <button
                      className="py-4 cursor-pointer text-red-500 flex items-center gap-1"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      <span>Logout</span>
                      <PiSignOut className="text-xl" />
                    </button>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Model {...popup} />
    </>
  );
};

export default EditUserPage;
