import React from "react";

const Model = ({ visible, title, text, onConfirm, onCancel }) => {
  return (
    <>
      {visible && (
        <>
          <div
            className="fixed w-screen h-screen bg-black top-0 left-0 opacity-40 z-[1000]"
            onClick={() => {
              onCancel();
            }}
          ></div>
          <div className="fixed bg-white w-full px-6 py-3 sm:px-12 sm:py-6 rounded w-full max-w-[500px] top-[20%] left-[50%] translate-x-[-50%] z-[1001]">
            <h1 className="text-2xl font-bold">{title}</h1>
            <p className="mt-2">{text}</p>
            <div className="flex items-center justify-between gap-x-8 mt-10">
              {onConfirm && (
                <button
                  className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded inline-block self-start"
                  onClick={() => {
                    onConfirm();
                  }}
                >
                  Bevestig
                </button>
              )}
              {onCancel && (
                <button
                  className="bg-gray-200 text-black py-2 px-4 rounded inline-block self-start"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Sluiten
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Model;
