import { formatDateForInput } from "./date";

export const formatUser = (user) => {
  Object.keys(user).forEach((key) => {
    if (user[key] === null) {
      user[key] = undefined;
    }
  });
  user.birthdate = formatDateForInput(user.birthdate);
  return user;
};
