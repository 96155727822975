import { React, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { registerUser } from "../services/AuthService";
import Authenticator from "../components/Authenticator";
import Input from "../components/Input";
import { useNavigate } from "react-router-dom";
import { verifyToken } from "../services/TokenService";
import { getCSRFToken } from "../services/TokenService";
import Logo from "../assets/logo.png";
import NavbarFrontPage from "../components/NavbarFrontPage";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const initialRegister = {
  firstname: "",
  lastname: "",
  email: "",
  phone: null,
  birthDate: null,
  city: "",
  zipcode: "",
  street: "",
  houseNumber: "",
  password: "",
  passwordRepeat: "",
};

const RegisterPage = () => {
  const ADMIN = 1;
  const RESET_PASSWORD = -1;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [secretUrl, setSecretUrl] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // Check if user is already logged in
  useEffect(() => {
    async function verify() {
      try {
        await getCSRFToken();
        const payload = await verifyToken();
        if (payload.role === RESET_PASSWORD) {
          navigate(`/user/resetPassword`);
        } else if (payload.role === ADMIN) {
          navigate("/users");
        } else {
          navigate("/dashboard");
        }
      } catch (e) {
        // User is not logged in or server error
        return;
      }
    }

    verify();
  }, []);

  const registerSchema = Yup.object().shape({
    firstname: Yup.string().required("Verplicht"),
    lastname: Yup.string().required("Verplicht"),
    email: Yup.string()
      .required("Verplicht")
      .email("Vul een geldig email adres in"),
    phone: Yup.string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
        "Vul een geldig telefoonnummer in",
      )
      .nullable(),
    birthDate: Yup.date("Vul een geldige datum in")
      .required("Verplicht")
      .min(new Date(Date.UTC(1900, 0, 1)), "Datum moet na 1900 zijn")
      .max(new Date(), "Geboortedatum kan niet in de toekomst liggen"),
    city: Yup.string().required("Verplicht"),
    zipcode: Yup.string()
      .required("Verplicht")
      .matches(/^[1-9]\d{3}\s?[A-Za-z]{2}$/, "Vul een geldige postcode in"),
    street: Yup.string().required("Verplicht"),
    houseNumber: Yup.number().required("Verplicht"),
    password: Yup.string()
      .required("Verplicht")
      .min(8, "Wachtwoord moet minimaal 8 karakters bevatten.")
      .matches(/\d+/, "Wachtwoord moet een nummer bevatten"),
    passwordRepeat: Yup.string()
      .required("Verplicht")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const transformEmptyStringsToNull = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        value === "" ? null : value,
      ]),
    );
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    data = transformEmptyStringsToNull(data);
    try {
      const secretUrl = await registerUser(data); // Returns secret key for mfa
      setEmail(data.email);
      setSecretUrl(secretUrl);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-center box-border pt-20 px-2 sm:px-4">
        <NavbarFrontPage />
        {!secretUrl ? (
          // Registration sreen
          <Formik
            initialValues={initialRegister}
            validationSchema={registerSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ errors, touched }) => (
              <Form className="bg-white inline-flex flex-col gap-y-4 py-4 px-2 sm:px-6 rounded-lg w-full max-w-xl mb-12">
                <h1 className="text-2xl text-black">Register</h1>
                {error && <span className="text-red-500">{error}</span>}
                <div className="grid grid-cols-2 w-full gap-4">
                  <Input
                    label="Voornaam"
                    error={errors.firstname}
                    touched={touched.firstname}
                    type="text"
                    name="firstname"
                  />
                  <Input
                    label="Achternaam"
                    error={errors.lastname}
                    touched={touched.lastname}
                    type="text"
                    name="lastname"
                  />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
                  <Input
                    label="E-mail"
                    error={errors.email}
                    touched={touched.email}
                    type="email"
                    name="email"
                  />
                  <Input
                    label="Telefoonnummer (optioneel)"
                    error={errors.phone}
                    touched={touched.phone}
                    type="tel"
                    name="phone"
                  />
                  <Input
                    label="Geboortedatum"
                    error={errors.birthDate}
                    touched={touched.birthDate}
                    type="date"
                    name="birthDate"
                  />
                </div>
                <div className="py-8 flex flex-col gap-4">
                  <div className="grid grid-cols-2 w-full gap-4">
                    <Input
                      label="Woonplaats"
                      error={errors.city}
                      touched={touched.city}
                      type="text"
                      name="city"
                    />
                    <Input
                      label="Postcode"
                      error={errors.zipcode}
                      touched={touched.zipcode}
                      type="text"
                      name="zipcode"
                    />
                  </div>
                  <div className="grid grid-cols-2 w-full gap-4">
                    <Input
                      label="Straatnaam"
                      error={errors.street}
                      touched={touched.street}
                      type="text"
                      name="street"
                    />
                    <Input
                      label="Huisnummer"
                      error={errors.houseNumber}
                      touched={touched.houseNumber}
                      type="text"
                      name="houseNumber"
                    />
                  </div>
                </div>
                <Input
                  label="Wachtwoord"
                  error={errors.password}
                  touched={touched.password}
                  type="password"
                  name="password"
                />
                <Input
                  label="Herhaal wachtwoord"
                  error={errors.passwordRepeat}
                  touched={touched.passwordRepeat}
                  type="password"
                  name="passwordRepeat"
                />
                {loading ? (
                  <AiOutlineLoading3Quarters className="animate-spin text-3xl mt-3" />
                ) : (
                  <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-2 px-10 rounded inline-block mt-4 self-start">
                    Register
                  </button>
                )}
                <span>
                  Heb je al een account? <br />{" "}
                  <button
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="text-indigo-700 underline"
                  >
                    Login
                  </button>
                </span>
              </Form>
            )}
          </Formik>
        ) : (
          // Authentication screen after registering
          <Authenticator secretUrl={secretUrl} email={email} />
        )}
      </div>
    </>
  );
};

export default RegisterPage;
