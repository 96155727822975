import React, { useEffect, useState } from "react";
import { getUsers } from "../services/UsersService";
import { GoArrowRight } from "react-icons/go";
import { getAge } from "../utils/date";
import Input from "../components/Input";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { FaPlus, FaMinus } from "react-icons/fa";
import { addUserInvite, deleteUserInvite } from "../services/UsersService";
import { useNavigate } from "react-router-dom";
import Model from "../components/Model";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const addUserInitialValues = {
  email: "",
};

const initialPopup = {
  visible: false,
  title: "",
  text: "",
  onConfirm: null,
  onCancel: null,
};

const UsersPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingAddUser, setLoadingAddUser] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [filteredInvited, setFilteredInvites] = useState([]);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [showInvites, setShowInvites] = useState(false);
  const [popup, setPopup] = useState(initialPopup);

  const addUserSchema = Yup.object().shape({
    email: Yup.string()
      .required("Verplicht")
      .email("Vul een geldig email adres in"),
  });

  async function onSubmit(data) {
    setSuccess(null);
    setError(null);
    setLoadingAddUser(true);
    try {
      const successMessage = await addUserInvite(data);
      setSuccess(successMessage);
      setOpenAddUser(false);

      setInvites([...invites, { email: data.email }]);
      setFilteredInvites([...filteredInvited, { email: data.email }]);
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        navigate("/dashboard");
      } else {
        setError(e.message);
      }
    } finally {
      setLoadingAddUser(false);
    }
  }

  async function fetchUsers() {
    setLoading(true);
    try {
      const data = await getUsers();
      const users = data.filter((user) => user.mfa_validated === true);
      setUsers(users);
      setFilteredUsers(users);
      const invites = data.filter((user) => user.mfa_validated !== true);
      setInvites(invites);
      setFilteredInvites(invites);
      setLoading(false);
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        navigate("/dashboard");
      } else {
        setLoading(false);
        setError(e.message);
      }
    }
  }

  async function deleteInvite(email) {
    setSuccess(null);
    setError(null);
    try {
      const successMessage = await deleteUserInvite({ email: email });
      setSuccess(successMessage);
      setOpenAddUser(false);

      setFilteredInvites(
        invites.filter((invite) => {
          return invite.email !== email;
        }),
      );
      setInvites(
        invites.filter((invite) => {
          return invite.email !== email;
        }),
      );
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        navigate("/dashboard");
      } else {
        setError(e.message);
      }
    } finally {
      setPopup(initialPopup);
    }
  }

  function filterUsers(e) {
    const searchTerm = e.target.value.toLowerCase();
    setFilteredUsers(
      users.filter((user) => {
        const fullName = `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`;
        return (
          fullName.includes(searchTerm) ||
          user.firstname.toLowerCase().includes(searchTerm) ||
          user.lastname.toLowerCase().includes(searchTerm)
        );
      }),
    );
  }

  function filterInvites(e) {
    const searchTerm = e.target.value.toLowerCase();
    setFilteredInvites(
      invites.filter((invite) => {
        return invite.email.toLowerCase().includes(searchTerm);
      }),
    );
  }

  function switchList() {
    setShowInvites(!showInvites);
    document.getElementById("gebruiker").value = "";
    setFilteredUsers(users);
    setFilteredInvites(invites);
    setError(null);
    setSuccess(null);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <div className="flex justify-center w-full max-h-[calc(100vh-3rem)] pb-12 sm:pb-20 box-border">
        <div className="bg-white inline-flex flex-col gap-y-4 py-4 px-2 sm:px-6 rounded-lg w-full max-w-xl">
          <div className="flex flex-col gap-y-2 border-b-2 pb-4">
            <div className="flex justify-between items-center flex-wrap gap-y-2">
              <div className="flex items-center gap-x-4">
                <h1 className="text-2xl text-black">
                  {showInvites ? "Uitnodigingen" : "Gebruikers"}
                </h1>
                <button
                  className="inline-block underline"
                  onClick={() => {
                    switchList();
                  }}
                >
                  {showInvites ? "Bekijk gebruikers" : "Bekijk uitnodigingen"}
                </button>
              </div>
              <button
                className="inline-block"
                onClick={() => {
                  setOpenAddUser(!openAddUser);
                }}
              >
                {openAddUser ? <FaMinus /> : <FaPlus />}
              </button>
            </div>
            {error && <span className="text-red-500">{error}</span>}
            {success && <span className="text-green-500">{success}</span>}
            {openAddUser && (
              <Formik
                initialValues={addUserInitialValues}
                validationSchema={addUserSchema}
                onSubmit={(values) => onSubmit(values)}
              >
                {({ errors, touched }) => (
                  <Form className="flex items-end gap-4">
                    <Input
                      label="E-mail"
                      type="email"
                      name="email"
                      error={errors.email}
                      touched={touched.email}
                    />
                    {loadingAddUser ? (
                      <AiOutlineLoading3Quarters className="animate-spin text-3xl mt-3" />
                    ) : (
                      <button className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded inline-block">
                        Voeg toe
                      </button>
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </div>
          <div className="flex flex-col gap-y-1 self-start">
            <label htmlFor="gebruiker">
              {showInvites ? "Zoek uitnodigingen:" : "Zoek gebruikers:"}
            </label>
            <input
              id="gebruiker"
              type="text"
              name="gebruiker"
              className="bg-grey-accent rounded-md p-2 block"
              onChange={(e) => {
                showInvites ? filterInvites(e) : filterUsers(e);
              }}
            />
          </div>
          <div className="max-h-screen overflow-auto">
            {!loading ? (
              showInvites ? (
                filteredInvited.map((invite, i) => (
                  <div
                    className="flex justify-between items-center border-b-[1px] px-4 py-3 hover:bg-[#FAFAFA] w-full text-start cursor-pointer"
                    key={i}
                  >
                    <div>
                      <h1>{invite.email}</h1>
                    </div>
                    <div className="flex items-center gap-x-4">
                      <button
                        className="underline text-red-500"
                        onClick={() => {
                          setPopup({
                            visible: true,
                            title: "Uitnodiging verwijderen",
                            text: `Weet je zeker dat je de uitnodiging naar ${invite.email} wilt verwijderen?`,
                            onConfirm: () => {
                              deleteInvite(invite.email);
                            },
                            onCancel: () => {
                              setPopup(initialPopup);
                            },
                          });
                        }}
                      >
                        Verwijder
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                filteredUsers.map((user, i) => (
                  <div
                    className="flex justify-between items-center border-b-[1px] px-4 py-3 hover:bg-[#FAFAFA] w-full text-start cursor-pointer"
                    key={i}
                    onClick={() => {
                      navigate(`/dashboard`, {
                        state: { userData: user },
                      });
                    }}
                  >
                    <div>
                      <h1>{user.firstname + " " + user.lastname}</h1>
                      <span>Leeftijd: {getAge(user.birthdate)}</span>
                    </div>
                    <div className="flex items-center gap-x-4">
                      <button
                        className="underline"
                        onClick={(e) => {
                          e.stopPropagation();
                          sessionStorage.setItem("user", JSON.stringify(user));
                          navigate(`/user/${user.uuid}`, {
                            state: { userData: user },
                          });
                        }}
                      >
                        Edit
                      </button>
                      <GoArrowRight className="text-xl" />
                    </div>
                  </div>
                ))
              )
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>
      <Model {...popup} />
    </>
  );
};

export default UsersPage;
